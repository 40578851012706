<template>
  <div style="margin: 20px">
    <el-card>
      <el-form :model="form" v-loading="loading" ref="form" :rules="rules">
        <el-form-item label="Наличие параметров">
<!--          <el-checkbox v-model="form.storage_not_null_value"/>-->
          <el-switch
            :disabled="!form.is_operator"
            v-model="form.storage_not_null_value"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item
          class="check-label"
          label="Согласно пункту 3.5. Порядка оценки деятельности исполнительных органов государственной власти Санкт-Петербурга (Постановление Правительства Санкт-Петербурга от 19.01.2018 N 4): «Отчет составляется и утверждается ИОГВ в произвольной форме, содержащей следующие обязательные сведения: наименование ИОГВ, отчетный период, перечень КПР, достигнутые в отчетном периоде значения, а также сведения о лицах, составивших и утвердивших Отчет»">
          <file-upload :disabled="!form.is_operator" v-if="!loading" v-model="form.files" @delete="deleteFile" @file-uploaded="fileUploaded"/>
        </el-form-item>
        <div v-if="form.pkiPeriodModel.pki.classification_id === 3">
          <el-form-item label="Комментарий" prop="storageModel.comment">
            <el-input type="textarea" v-model="form.storageModel.comment" show-word-limit maxlength="501"/>
          </el-form-item>
          <el-form-item label="Международное сопоставление показателя">
            <el-input v-model="form.storageModel.international_comparisons_of_indicators"/>
          </el-form-item>
          <el-form-item label="Информация о документах стратегического планирования">
            <el-input v-model="form.storageModel.information_on_strategic_planning_documents"/>
          </el-form-item>
        </div>
        <el-form-item label="Комментарий оператора ввода" prop="reason_by_operator">
          <el-input :disabled="!form.is_operator" type="textarea" v-model="form.reason_by_operator" show-word-limit maxlength="501"/>
        </el-form-item>
        <div v-if="form.pkiPeriodModel.pki.classification_id === 4">
          <template v-if="form.is_ranked">
            <el-form-item label="Пояснение от оцениваемого ИОГВ">
              <br>
              <file-upload v-if="!loading" v-model="form.iogv_files" @delete="deleteFile" @file-uploaded="iogvFileUploaded"/>
            </el-form-item>
            <el-form-item label="Комментарий ИОГВ" prop="comment">
              <el-input type="textarea" v-model="form.comment" show-word-limit maxlength="501"/>
            </el-form-item>
          </template>
        </div>
        <div v-if="form.storage_not_null_value && form.pkiPeriodModel.rule === 1">
          <p class="m-t-5">
            Формула расчета:
            <katex-element :expression="getFormula(form.pkiPeriodModel.formula)"/>
          </p>
          <el-form-item v-if="form.pkiPeriodModel.rating_by_target" label="Целевое значение">
            <el-input :disabled="form.pkiPeriodModel.pki.target_value !== null || !form.is_operator" v-model="form.pkiPeriodModel.target_value" type="number"/>
          </el-form-item>
          <el-form-item v-else-if="form.pkiPeriodModel.target_value !== null" label="Целевое значение">
            <el-input disabled v-model="form.pkiPeriodModel.target_value" type="number"/>
          </el-form-item>
          <div
            v-for="(item, idx) in form.valueStorageModels"
            :key="item.id"
            class="check-label">
            <el-row :gutter="20">
              <el-col :span="12">
                <label class="el-form-item__label">
                  <katex-element :expression="getFormula(item.valueRule.designatio)"/>
                  - {{ item.valueRule.description }} {{item.valueRule.unitOfMeasurement ? `(${item.valueRule.unitOfMeasurement.short_name})` : ''}}
                </label>
              </el-col>
              <el-col :span="12">
                <label class="el-form-item__label">
                  Интерпретация вводимых значений
                </label>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :prop="'valueStorageModels.' + idx +'.value'"
                  :rules="{ required: true, message: 'Не заполнено обязательное поле', trigger: 'change' }">
                  <template slot="label">
                  </template>
                  <input v-model="form.valueStorageModels[idx].value"
                         type="number"
                         class="el-input__inner">
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <input v-model="form.valueStorageModels[idx].comment"
                         class="el-input__inner">
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div
            v-for="(item, idx) in form.masters"
            :key="item.id"
            class="check-label">
            <el-form-item>
              <template slot="label">
                <katex-element :expression="getFormula(item.label)"/>
              </template>
              <el-input
                v-model="form.masters[idx].result"
                disabled/>
            </el-form-item>
          </div>
        </div>
        <div v-if="form.storage_not_null_value && form.pkiPeriodModel.rule === -1">
          <el-form-item v-if="form.pkiPeriodModel.rating_by_target" label="Целевое значение">
            <el-input :disabled="form.pkiPeriodModel.pki.target_value !== null || !form.is_operator" v-model="form.pkiPeriodModel.target_value" type="number"/>
          </el-form-item>
          <el-form-item v-else-if="form.pkiPeriodModel.target_value !== null" label="Целевое значение">
            <el-input disabled v-model="form.pkiPeriodModel.target_value" type="number"/>
          </el-form-item>
          <el-form-item label="Результат">
            <el-input v-model="form.storageModel.result" type="number"/>
          </el-form-item>
        </div>
        <div v-if="form.storage_not_null_value && form.pkiPeriodModel.rule === 0">
          <el-form-item label="Результат">
            <el-select v-model="form.storageModel.result">
              <el-option
                v-for="item in form.selectOptions"
                :key="item.value"
                :label="item.value"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" @click="save" :disabled="lockButton">Сохранить</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload/Files'
import { getStorageDetail, saveStorage, getStorageKpDetail, saveStorageKp } from '@/api/api'
import AsciiMathParser from 'asciimath2tex'

export default {
  components: {
    FileUpload
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      rules: {
        comment: [
          { max: 500, message: 'Превышено допустимое количество знаков (500)!', trigger: ['blur', 'change'] }
        ],
        reason_by_operator: [
          { max: 500, message: 'Превышено допустимое количество знаков (500)!', trigger: ['blur', 'change'] }
        ],
        'storageModel.comment': [
          { max: 500, message: 'Превышено допустимое количество знаков (500)!', trigger: ['blur', 'change'] }
        ]
      },
      loading: false,
      lockButton: false,
      form: {
        storage_not_null_value: null,
        is_operator: null,
        is_ranked: null,
        storageModel: {
          id: null,
          pki_id: null,
          period_id: null,
          result: '',
          rating: null,
          ebosp_id: null,
          toris_user_id: null,
          create_at: null,
          comment: null,
          international_comparisons_of_indicators: null,
          information_on_strategic_planning_documents: null,
          reason_by_ebosp: null,
          reason_by_operator: '',
          last_editor: null
        },
        pkiPeriodModel: {
          pki_id: null,
          period_id: null,
          formula: '',
          rule: null,
          type: null,
          target_value: null,
          target_reachable: null,
          rating_by_target: null,
          accuracy: null,
          clarification: null,
          name: null,
          pki_index: null,
          code: null,
          nso_code: null,
          nso_pki_id: null,
          comment_only: null,
          main_direction_activity_id: null,
          pki: {
            id: null,
            name: null,
            pki_id: null,
            classification_id: null,
            valid: null,
            type: null,
            formula: '',
            rule: null,
            target_value: null,
            target_reachable: null,
            rating_by_target: null,
            code: null,
            accuracy: null,
            clarification: null,
            nso_code: null,
            nso_pki_id: null,
            main_direction_activity_id: null,
            comment_only: null
          }
        },
        valueStorageModels: [],
        selectOptions: [],
        masters: [],
        comment: null,
        reason_by_operator: '',
        files: [],
        iogv_files: []
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      if (this.$route.meta.isKP) {
        getStorageKpDetail(this.id || this.$route.params.id).then(response => {
          this.form = response.data
        }).finally(() => {
          this.loading = false
        })
      } else {
        getStorageDetail(this.id || this.$route.params.id).then(response => {
          this.form = response.data
        }).finally(() => {
          this.loading = false
        })
      }
    },
    getFormula (val) {
      val = val.replaceAll('`', '')
      const parser = new AsciiMathParser()
      return parser.parse(val).replaceAll('∑', 'Σ')
    },
    save () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.lockButton = true
          if (this.$route.meta.isKP) {
            saveStorageKp(this.form).then((response) => {
              this.$message({
                message: 'Успешно сохранено!',
                type: 'success'
              })
              this.$emit('updated', response.data)
              if (response.data.pkiPeriodModel.unitOfMeasurement.short_name === '%' && response.data.storageModel.result > 100) {
                this.$alert('Рекомендуется перепроверить введенные значения параметров, так как рассчитанный результат составил более 100%. Если это верный результат, не обращайте внимания на данное предупреждение', 'Внимание', {
                  confirmButtonText: 'OK'
                })
              }
            }).finally(() => {
              this.lockButton = false
            })
          } else {
            saveStorage(this.form).then((response) => {
              this.$message({
                message: 'Успешно сохранено!',
                type: 'success'
              })
              this.$emit('updated', response.data)
              if (response.data.pkiPeriodModel.unitOfMeasurement.short_name === '%' && response.data.storageModel.result > 100) {
                this.$alert('Рекомендуется перепроверить введенные значения параметров, так как рассчитанный результат составил более 100%. Если это верный результат, не обращайте внимания на данное предупреждение', 'Внимание', {
                  confirmButtonText: 'OK'
                })
              }
            }).finally(() => {
              this.lockButton = false
            })
          }
        }
      })
    },
    deleteFile (id) {
      this.form.files = this.form.files.filter(x => x.id !== id)
    },
    fileUploaded (id) {
      this.form.files.push({ id })
    },
    iogvFileUploaded (id) {
      this.form.iogv_files.push({ id })
    }
  }
}
</script>

<style scoped>
.check-label >>> label {
  line-height: 1.2;
  word-break: break-word;
  margin-bottom: 5px;
  font-weight: 100;
  text-align: left;
}
</style>
